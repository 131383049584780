import {defineRule} from 'vee-validate'
import {email, required, min, max} from '@vee-validate/rules'

function validatePassword(value: string | undefined) {
  const SPECIAL_CHAR_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  const UPPERCASE_REGEX = /[A-Z]/
  const LOWERCASE_REGEX = /[a-z]/
  const NUMBER_REGEX = /[0-9]/

  const errorMessage = []

  if (!min(value, {length: 7})) {
    errorMessage.push('at least 7 characters')
  }

  if (value) {
    if (!SPECIAL_CHAR_REGEX.test(value)) {
      errorMessage.push('at least one special character')
    }
    if (!UPPERCASE_REGEX.test(value)) {
      errorMessage.push('at least one uppercase letter')
    }
    if (!LOWERCASE_REGEX.test(value)) {
      errorMessage.push('at least one lowercase letter')
    }
    if (!NUMBER_REGEX.test(value)) {
      errorMessage.push('at least one number')
    }
  }

  if (errorMessage.length) {
    if (errorMessage.length > 1) {
      errorMessage[errorMessage.length - 1] = 'and ' + errorMessage[errorMessage.length - 1]
      return 'Must contain ' + errorMessage.join(', ') + '.'
    } else {
      return 'Must contain ' + errorMessage.join('') + '.'
    }
  }

  return true
}

defineRule('required', (value: string | number | undefined) => {
  if (required(value)) {
    return true
  }

  return 'This field is required.'
})

defineRule('email', (value: string | undefined) => {
  if (email(value) && required(value) && max(value, {length: 200})) {
    return true
  }

  if(value === '')
  {
    return 'This field is required.'
  }

  return 'Please enter a valid email address.'
})

defineRule('password', (value: string | undefined) => {
  const PW_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/

  if (required(value) &&
        value &&
        min(value, {length: 7}) &&
        max(value, {length: 100}) &&
        PW_REGEX.test(value)) {
    return true
  }
  return validatePassword(value)
})

defineRule('confirmPassword', (value: string | undefined, [target]: string) => {
  if (required(value) && value === target) {
    return true
  }

  if(value === '')
  {
    return 'This field is required.'
  }

  return 'Passwords must match.'
})

defineRule('validFirstName', (value: string | undefined) => {
  const FIRST_NAME_REGEX = /^[A-ZÀ-Ýa-zà-ÿ\- ]{2,35}$/

  if (value && (!value.length || FIRST_NAME_REGEX.test(value))) {
    return true
  }

  return 'Name is not valid.'
})

defineRule('validName', (value: string | undefined) => {
  const NAME_REGEX = /^[A-ZÀ-Ýa-zà-ÿ\- ]{2,35}$/

  if ((value && NAME_REGEX.test(value)) || !value || (value && !value.length)) {
    return true
  }

  return 'Name is not valid.'
})

defineRule('validSuffix', (value: string | undefined) => {
  const SUFFIX_REGEX = /^[^\s\d\W][A-ZÀ-ÖØ-ßa-zà-öø-ÿ ,.-]*[^\s\d:-@!--/[-_{-~]$/g

  if ((value && SUFFIX_REGEX.test(value)) || !value || (value && !value.length)) {
    return true
  }

  return 'Suffix is not valid.'
})

defineRule('validPostalCode', (value: string | undefined) => {
  const POSTAL_CODE_REGEX = /^[0-9a-zA-Z]+[- ]?[0-9a-zA-Z]+$/g

  if (value && min(value, {length: 5}) && POSTAL_CODE_REGEX.test(value)) {
    return true
  }

  if(value === '')
  {
    return 'This field is required.'
  }

  return 'Postal Code is not valid.'
})

defineRule('validPhoneNumber', (value: string | undefined) => {

  const PHONE_NUMBER_REGEX = /^[0-9]{10}/g

  if (required(value) && max(value, {length: 10}) && (value && PHONE_NUMBER_REGEX.test(value))) {
    return true
  }

  if(value === '')
  {
    return 'This field is required.'
  }

  return 'Phone number should only include numbers and must be 10 digits.'
})

defineRule('primaryAccreditation', (data: Record<string, string> | undefined) => {
  if(data?.value === '' || data?.value ===  ' ' || data?.value === undefined)
  {
    return 'This field is required.'
  }

  return true
})

defineRule('secondaryAccreditation', (data: Record<string, string> | undefined) => {
  const CREDENTIAL_REGEX = /^[0-9a-zA-Z. &]+$/g

  if(data?.value !== undefined || data?.value === ' ')
  {
    if ((data?.value && CREDENTIAL_REGEX.test(data?.value))) {
      return true
    } else {
      return 'Please Select An Option.'
    }
  }

  return true
})