import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import Customer from '../types/Customer'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'
import { log } from '../../../../global/js/utils/logging'

interface GetCustomerResponse {
  data: {
    customer: Customer | null
  }
  errors?: [{ message: string }]
}

export const customerQuery = gql`
  query {
    customer {
      entity_id
      prefix
      firstname
      middlename
      lastname
      email
      date_of_birth
      media_job_title
      media_phone_extension
      addresses {
        id
        company
        telephone
        fax
        street
        city
        default_billing
        default_shipping
        region {
          region
          region_id
          region_code
        }
        postcode
        country_code
      }
      media_npi_number
      media_abim_membership
      media_abim_member_id
      media_secondary_board_id
      media_abms_board
      media_credentials
      media_credential_secondary
      media_specialty
      media_profession
      freecme_credentials
      freecme_specialty
      freecme_profession
      freecme_credentials_2
      freecme_specialty_2
      freecme_profession_2
      freecme_credentials_3
      freecme_specialty_3
      freecme_profession_3
      fcme_user_profile
      prefix
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getCustomer = async (): Promise<Customer | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<GetCustomerResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: customerQuery,
    },
  })

  if (data.errors) {
    log(data.errors[0].message as string, 'src/global/js/magento-api-library/customer/getCustomer.ts')

    if (data.errors[0].message == 'The current customer isn\'t authorized.') {
      //The token locally has expired or is otherwise bad
      localStorage.removeItem('customer')
      localStorage.removeItem('authToken')
    }
    throw data.errors[0].message
  }

  if (data.data.customer) {
    return data.data.customer
  }

  return null
}