import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface UpdateCustomerResponse {
  data: {
    updateCustomerV2: {
      customer: {
        prefix: string
      }
    } | null
  }
  errors?: [{ message: string }]
}

interface UpdateFreeCmeCustomerResponse {
  data: {
    updateCustomerV2: {
      customer: {
        prefix: string
      }
    } | null
  }
  errors?: [{ message: string }]
}

export const updateFreeCmeCustomerMutation = gql`
  mutation(
   $credentials: String!,
   $specialty: String!,
   $profession: String!,
   $secondaryCredentials: String,
   $secondarySpecialty: String,
   $secondaryProfession: String,
   $ternaryCredentials: String,
   $ternarySpecialty: String,
   $ternaryProfession: String,
   $userProfile: String
   ) {
    freeCmeUpdateCustomer(input: { 
    freecme_credentials: $credentials,
    freecme_specialty: $specialty,
    freecme_profession: $profession,
    freecme_credentials_2: $secondaryCredentials,
    freecme_specialty_2: $secondarySpecialty,
    freecme_profession_2: $secondaryProfession,
    freecme_credentials_3: $ternaryCredentials,
    freecme_specialty_3: $ternarySpecialty,
    freecme_profession_3: $ternaryProfession,
    fcme_user_profile: $userProfile
     }) {
      customer {
        prefix
        date_of_birth
      }
    }
  }
`

export const updateCustomerMutation = gql`
  mutation($prefix: String,
   $jobTitle: String,
   $phoneExtension: String,
   $birthdate: String,
   $npiNumber: String,
   $abimMemberId: String,
   $abmsBoardId: String,
   $primaryCredentials: String,
   $secondaryCredentials: String,
   $abimMembers: String,
   $abmsBoard: String,
   $specialty: String,
   $profession: String
   ) {
    reliasMediaUpdateCustomer(input: { 
    prefix: $prefix,
    media_job_title: $jobTitle,
    media_phone_extension: $phoneExtension,
    date_of_birth: $birthdate,
    media_npi_number: $npiNumber,
    media_abim_member_id: $abimMemberId,
    media_secondary_board_id: $abmsBoardId,
    media_credentials: $primaryCredentials,
    media_credential_secondary: $secondaryCredentials,
    media_abim_membership: $abimMembers,
    media_abms_board: $abmsBoard,
    media_specialty: $specialty,
    media_profession: $profession
     }) {
      customer {
        prefix
        date_of_birth
      }
    }
  }
`
/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const updateCustomer = async (
  prefix: string,
  jobTitle: string,
  phoneExtension: string,
  birthdate: string,
  npiNumber: string,
  abimMemberId: string,
  abmsBoardId: string,
  primaryCredentials: string,
  secondaryCredentials: string,
  abimMembers: string,
  abmsBoard: string,
  specialty: string,
  profession: string,
): Promise<string> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<UpdateCustomerResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: updateCustomerMutation,
      variables: {
        prefix,
        jobTitle,
        phoneExtension,
        birthdate,
        npiNumber,
        abimMemberId,
        abmsBoardId,
        primaryCredentials,
        secondaryCredentials,
        abimMembers,
        abmsBoard,
        specialty,
        profession,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.updateCustomerV2) {
    return data.data.updateCustomerV2.customer.prefix
  }

  return ''
}

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const updateFreeCmeCustomer = async (
  credentials: string,
  specialty: string,
  profession: string,
  secondaryCredentials: string,
  secondarySpecialty: string,
  secondaryProfession: string,
  ternaryCredentials: string,
  ternarySpecialty: string,
  ternaryProfession: string,
  userProfile?: string | undefined,
): Promise<string | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<UpdateFreeCmeCustomerResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: updateFreeCmeCustomerMutation,
      variables: {
        credentials,
        specialty,
        profession,
        secondaryCredentials,
        secondarySpecialty,
        secondaryProfession,
        ternaryCredentials,
        ternarySpecialty,
        ternaryProfession,
        userProfile,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.updateCustomerV2) {
    return data.data.updateCustomerV2.customer.prefix
  }

  return null
}
